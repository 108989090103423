<template>
    <div>
        <div ref="pie_chart"></div>
    </div>
</template>

<script>
import { Chart, Util } from '@antv/g2';

export default {
  async mounted() {
    await this.$store.dispatch('pieChartDump');
    await this.pieBuild();
  },

  methods: {
    async pieBuild() {
      const data = await this.$store.state.pie_data;
      const chart = new Chart({
        container: this.$refs.pie_chart,
        autoFit: true,
        height: 500,
      });
      chart.data(data);

      chart.coordinate('theta', {
        radius: 1,
      });
      chart.tooltip({
        showMarkers: false,
      });

      // eslint-disable-next-line no-unused-vars
      const interval = chart
        .interval()
        .adjust('stack')
        .position('value')
        .color('type', ['#063d8a', '#1770d6', '#47abfc', '#38c060'])
        .style({ opacity: 0.4 })
        .state({
          active: {
            style: (element) => {
              const { shape } = element;
              return {
                matrix: Util.zoom(shape, 1.1),
              };
            },
          },
        })
        .label('type', (val) => {
          const opacity = val === '四线及以下' ? 1 : 0.5;
          return {
            offset: -30,
            style: {
              opacity,
              fill: 'white',
              fontSize: 12,
              shadowBlur: 2,
              shadowColor: 'rgba(0, 0, 0, .45)',
            },
            content: (obj) => `${obj.type}\n${obj.value}%`,
          };
        });

      chart.interaction('element-single-selected');

      chart.render();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
