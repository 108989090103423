<template>
  <div class="home-container">
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Layout Editor</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <!-- <div class="modal-body">
            <LayoutController />
          </div> -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="split-layout-comp">
      <Layout :key="componentKey" :resize="resize" :edit="edit" :splits="tree">
        <Pane
          v-for="layoutKey in layoutKeys"
          :key="layoutKey"
          class="split-layout-custom-style"
        >
          <div class="tab-top nav-box">
            <ul class="nav nav-tabs nav-overflow">
              <li
                v-for="(value, key) in layout[layoutKey]"
                :key="key"
                class="nav-item"
              >
                <div
                  class="nav-link d-flex align-items-center widget-tab"
                  :class="
                    layout_selection[layoutKey] === key
                      ? 'widget-tab__active'
                      : ''
                  "
                  @click="updateLayout(layoutKey, key)"
                  @click.middle="removeTabFromLayout(layoutKey, key)"
                  :title="getShortcut(findTabTitle(value), findTabTitle(value))"
                >
                  <i
                    class="custom-help-css box-icon widget-tab__button widget-tab__button--front bx bx-help-circle"
                    :title="getShortcut('Help', 'Help')"
                    :value="value"
                    @click="help(value)"
                  ></i>
                  <div class="tab-title">
                    {{ findTabName(value) }}
                  </div>
                  <i
                    class="custom-box-css box-x box-icon widget-tab__button widget-tab__button--back bx bx-x"
                    title="Close"
                    @click.stop="removeTabFromLayout(layoutKey, key)"
                  ></i>
                </div>
              </li>
              <li class="nav-item">
                <div class="dropdown nav-icon">
                  <component-dropdown-vue :componentOptionsData="componentsOptions" :layoutKey="layoutKey" :addToWidget="addTabToLayout"/>
                </div>
              </li>
            </ul>
          </div>
          <div class="tab-full">
            <component
              :is="layout[layoutKey][layout_selection[layoutKey]]"
            ></component>
          </div>
        </Pane>
      </Layout>
    </div>
  </div>
</template>
<script>
import { Layout, Pane } from 'vue-split-layout';
import Recentevents from '@/common/components/Recentevents.vue';
import SearchedWord from '@/common/components/SearchedWord.vue';
import LinksVisited from '@/common/components/LinksVisited.vue';
import PieDump from '@/common/components/PieDump.vue';
import RecentActivityWidget from '@/common/widgets/RecentActivityWidget/RecentActivityWidget.vue';
import ComponentDropdownVue from '@/common/components/ComponentDropdown.vue';
import UserAnalyticsWidget from '@/common/widgets/UserAnalyticsWidget/UserAnalyticsWidget.vue';
import UserEventsGraphWidget from '@/common/widgets/UserEventsGraphWidget/UserEventGraphWidget.vue';

export default {
  name: 'PageDashboard',

  metaInfo: {
    title: 'Dashboard',
  },

  components: {
    Recentevents,
    Layout,
    Pane,
    SearchedWord,
    PieDump,
    LinksVisited,
    RecentActivityWidget,
    ComponentDropdownVue,
    UserAnalyticsWidget,
    UserEventsGraphWidget,
  },
  data() {
    return {
      componentKey: 0,
      tree: {
        dir: 'horizontal', // Left | Right
        // Other split
        first: 0,
        second: {
          dir: 'horizontal',
          first: {
            dir: 'vertical',
            first: 1,
            second: 2,
          },
          second: {
            dir: 'vertical',
            first: 3,
            second: 4,
          },
          split: '55%',
        },
        split: '25%',
      },
      layoutKeys: ['_a', '_b', '_c', '_d', '_e'],
      layout: {
        _a: ['RecentActivityWidget'],
        _b: ['UserAnalyticsWidget', 'Recentevents', 'UserEventsGraphWidget'],
        _c: ['LinksVisited'],
        _d: ['SearchedWord'],
        _e: ['PieDump'],
      },
      layout_selection: {
        _a: 0,
        _b: 2,
        _c: 0,
        _d: 0,
        _e: 0,
      },
      componentsOptions: [
        {
          name: 'UserEventsGraphWidget',
          component: UserEventsGraphWidget,
          options: {
            title: 'Events Graph',
            description: 'View all your events in graph',
          },
        },
        {
          name: 'Recentevents',
          component: Recentevents,
          options: {
            title: 'Recent Events',
            description: 'View all your events',
          },
        },
        {
          name: 'RecentActivityWidget',
          component: RecentActivityWidget,
          options: {
            title: 'Recent Activity',
            description: 'View all your Activities',
          },
        },
        {
          name: 'PieDump',
          component: PieDump,
          options: {
            title: 'Pie Dump',
            description: 'View all your notes',
          },
        },
        {
          name: 'LinksVisited',
          component: LinksVisited,
          options: {
            title: 'Visited Links',
            description: 'Top most Links Visited',
          },
        },
        {
          name: 'SearchedWord',
          component: SearchedWord,
          options: {
            title: 'Word Cloud',
            description: 'Top most Searched Words',
          },
        },
        {
          name: 'UserAnalyticsWidget',
          component: UserAnalyticsWidget,
          options: {
            title: 'User Analytics',
            description: 'Able to Show users Analytics to you',
          },
        },
      ],
    };
  },
  methods: {
    findTabName(value) {
      const tab = this.componentsOptions.find((item) => item.name === value);
      return tab?.options?.title;
    },
    findTabTitle(value) {
      const tab = this.componentsOptions.find((item) => item.name === value);
      return tab?.name;
    },
    addPanel() {
      const panelId = Math.floor(Math.random() * 100000);
      const newPanelId = String(panelId);
      this.layoutKeys = [...this.layoutKeys, newPanelId];

      const newLayout = {};
      const newLayoutSelection = {};
      Object.keys(this.layout).forEach((key) => {
        newLayout[key] = this.layout[key];
      });
      newLayout[newPanelId] = [];

      this.layout = newLayout;
      Object.keys(this.layout_selection).forEach((key) => {
        newLayoutSelection[key] = this.layout_selection[key];
      });
      newLayoutSelection[newPanelId] = 0;
      this.layout_selection = newLayoutSelection;
      const customTree = {
        dir: 'horizontal',
        first: this.tree,
        second: this.layoutKeys.length - 1,
        split: '95%',
      };

      this.tree = customTree;
      this.componentKey += 1;
      // this.customTree = {
      //   dir: horizontal,

      // };
    },
    updateLayout(key, index) {
      this.layout_selection[key] = index;
      // this.updateLayoutToState();
    },
    removeTabFromLayout(key, index) {
      this.layout[key].splice(index, 1);
      // this.updateLayoutToState();
    },
    addTabToLayout(key, component) {
      this.layout[key].push(component);
      // this.updateLayoutToState();
    },
    // merge this.layout with this.$store.state.layout.layout_tabs
    // updateLayoutBasedOnState() {
    //   this.layout = this.$store.state.layout.layout_tabs;
    //   this.layout_selection = this.$store.state.layout.layout_tab_selection;

    // },
    // updateLayoutToState() {
    //   this.$store.dispatch('updateLayoutSettings', {
    //     layout: this.layout,
    //     layout_selection: this.layout_selection,
    //   });
    // },
    getShortcut(label, _key) {
      return this.$fetchShortcut(label, _key);
    },
  },

  computed: {
    randomNumer() {
      return Math.random();
    },
    showMeta() {
      if (this.$store.state.wikiInformation) {
        const wikiInfoLength = this.$store.state.wikiInformation.length;
        if (wikiInfoLength > 0) {
          return true;
        }
      }
      if (
        this.$store.state.selectedNode
        && this.$store.state.selectedNode.nodeLabel
      ) {
        return true;
      }

      if (
        this.$store.state.wolframInformation
        && this.$store.state.wolframInformation.answer
      ) {
        return true;
      }
      return false;
    },

    showExploreDescription() {
      return this.$store.state.explore.extract;
    },
    edit() {
      return this.$store.state.layouts.edit;
    },
    resize() {
      return this.$store.state.layouts.resize;
    },
  },
  mounted() {
    const thoughtId = this.$route.params.id;

    if (thoughtId) {
      this.$store.dispatch('getSavedTjState', thoughtId);
    }
    // listen root events
    this.$root.$on('addPanel', (payload) => {
      if (payload === 'FirstView') {
        this.addPanel();
      }
    });
    // this.updateLayoutBasedOnState();
    // this.unsubscribe = this.$store.subscribe((mutation) => {
    //   if (
    //     mutation.type === 'SET_LAYOUT_SETTINGS'
    //     || mutation.type === 'SET_SAVED_TJ_STATE'
    //   ) {
    //     this.updateLayoutBasedOnState();
    //   }
    // });
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$root.$off('addPanel', () => {
      this.addPanel();
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/abstracts/variables";

// #split-layout-comp {
//   height: 105vh;
//   z-index: -5;
//   padding: 1em;
// }
.split-layout-custom-style {
  border-radius: 5px;
  overflow-x: hidden !important;
}

.nav-tabs {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.nav-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 4px 4px 0 0;
  gap: 0.5em;

  &:focus,
  &:hover {
    background: #f2f2f2;
    cursor: pointer;
  }
}

.nav-link {
  color: rgba(0, 0, 0, 0.4);
}

.nav-tabs {
  border-bottom: 0;
}
.nav-item {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.tab-top {
  margin-bottom: 1em;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: $white;
  z-index: 2;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.tab-full {
  padding: 10px;
  height: 100%;
  position: relative;
}

.tab-content {
  display: flex;
  align-items: center;
}

.tab-active {
  .tab-content {
    color: $edvantaBlue;

    .tab-title {
      padding-bottom: 0.3em;
      border-bottom: 3px solid $edvantaYellow;
    }
  }
}

.custom-box-css {
  border-radius: 50%;
  transition: all ease-in-out 200ms;

  &:hover {
    cursor: pointer;
    background: rgba(154, 160, 166, 0.157);
  }
}

.custom-help-css {
  margin-top: 0.5%;
  // height: 20px;
  // width: 20px;
}

.box-x {
  padding: 2px;
}

.component-menu {
  max-height: 12em;
  overflow: auto;
}
</style>
