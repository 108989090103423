<template>
  <div class="frm_content">
    <div>
      <!-- <div class="modal-dialog"> -->
        <!-- <div> -->
          <IndexSelection @queryDetail="getQuery" v-if="toggleForm" :position="indexPostion"/>
          <div class=" d-flex justify-content-between align-items-center">
            <h5 class="frm_heading">{{title}}</h5>
            <i title="Detail Mode" class="bx bx-window-alt box-icon" @click="toggleEditMode"/>
          </div>
          <!-- <div class="modal-body"> -->
            <!-- A bootstrap form with single input field and textarea -->
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default">Label</span>
              <input type="text" v-model.lazy="formData.label" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default">Type</span>
              <select
                class="form-select form-select lang-select"
                aria-label="Description Modes"
                v-model="shape"
                title="Change Modes"
              >
                <option
                  class="mode-select__option"
                  v-for="option in modeList"
                  :value="option"
                  :key="option"
                >
                  {{ option }}
                </option>
              </select>
              <!-- <input type="text" v-model.lazy="formData.type" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"> -->
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default">Color</span>
              <color-picker v-model="color" :value="color" />
              <!-- <input type="text" v-model.lazy="formData.style.fill" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"> -->
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default">Size</span>
              <input type="text" v-model="formData.size" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" readonly>
              <input
                type="range"
                v-model="size"
                min="1"
                max="200"
                class="form-range ed-slider"
                id="customRange2"
              />
            </div>
            <div class="input-group">
              <span class="input-group-text">Description</span>
              <mavon-editor
                rows="4"
                id="detailEditor"
                placeholder="Add to note..."
                :showShortCut="false"
                class="mavonEditor"
                v-model="formData.description.content"
                :subfield="false"
                :toolbars=mavonToolbar
                :language="'en'" />
            </div>
            <a-select
              show-search
              placeholder="Link Quest"
              option-filter-prop="children"
              style="width: 100%"
              :filter-option="filterOption"
              @search="handleSearch"
              @focus="handleFocus"
              @blur="handleBlur"
              @change="handleChange"
            >
              <a-select-option v-for="quest in quests" :key="quest._id" :value="quest._id">
                {{ quest.g6_data.graph_name }}
              </a-select-option>
            </a-select>
            <div class="input-group input-group-lg">
              <span class="input-group-text" id="inputGroup-sizing-lg">Tags</span>
              <input type="text" v-model.lazy="formData.tags" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
            </div>
            <div class="node-properties">
              <div class="d-flex justify-content-between p-2 node-properties__header">
              <h5>Add Properties</h5>
              <div class="node-properties__toolbox">
                <i class="box-icon box-icon--effect bx bx-cloud" title="Convert them into Nodes" @click="convertAllInNodes(propCount)"></i>
                <i class='box-icon box-icon--effect bx bx-message-square-add' title="Add Property" @click="addPropField()"></i>
              </div>
            </div>
            <div id="propsRef" class="p-2 node-properties__body" ref="propsRef">
              <div v-for="prop in propCount" :key="prop" class="d-flex flex-wrap node-properties__form">
                <input class="m-1 p-2 node-properties__input" type="text" :value="prop[0]" placeholder="Enter property name">
                <input class="m-1 p-2 node-properties__input" type="text" :value="prop[1].value" placeholder="Enter property value">
                <i class="m-1 bx bx-send box-icon" title="Create Node From Property" @click="createNodeAsProperty(prop[0], prop[1])"></i>
              </div>
            </div>
            <div class="p-2 input-nodes" v-if="formData.tjType === 'function'">
              <div class="input-node__header">
                <h5>Input Nodes</h5>
                <!-- toolbar -->
                <!-- <i class="box-icon box-icon--effect bx bx-cloud" title="" @click="convertAllInNodes(propCount)"></i> -->
                <i class='box-icon box-icon--effect bx bx-message-square-add' title="Add Input" @click="addPropField()"></i>
              </div>
              <div class="input-node__body" v-if="inputNodes.length > 0">
                <div class="input-node__form" v-for="eachNode in inputNodes" :key="eachNode._cfg.id">
                  <input type="text" class="m-1 p-2 input-node__input ed-input" placeholder="Node Name" :value="eachNode._cfg.id">
                  <i class="m-1 bx bx-trash box-icon" title="Remove a Node from function" @click="removeInputFromFunction()"></i>
                </div>
              </div>
            </div>
            <div class="p-2 functional-computation" v-if="formData.tjType === 'function'">
              <div class="functional-computation__header">
                <h5>Processing</h5>
              </div>
              <div class="p-2 functional-computation__body">
                <div class="p-2 functional-computation__group">
                  <label for="functional-computation__variable" class="ed-label">Select Variable</label>
                  <select v-model="selectFunctionVariable">
                    <option value="none">Select</option>
                    <option value="intent">Intent</option>
                  </select>
                </div>
                <div class="p-2 functional-computation__group">
                  <label for="functional-computation__type"  class="ed-label">Choose {{ selectFunctionVariable }}</label>
                  <select v-model="functionVariable.intent" v-if="selectFunctionVariable === 'intent'">
                    <option v-for="prompt in prompts" :key="prompt.id" :value="prompt.id">{{ prompt.label }}</option>
                  </select>
                </div>
                <div class="p-2 functional-computation__group">
                  <label for="function-computation__prompt" class="ed-label">Enter your Prompt</label>
                  <input type="text" class="ed-input" v-model="functionalPrompt" placeholder="Enter your prompt">
                </div>
              </div>
            </div>
            <div class="p-2 funtional-output" v-if="formData.tjType === 'function'">
              <div class="functional-output__header">
                <h5>Output</h5>
              </div>
              <div class="p-2 funtional-output__body">
                <div class="p-2 functional-output__group">
                  <label for="functional-output__variable" class="ed-label">Select Variable</label>
                  <select v-model="functionalOutput">
                    <option value="none">Select</option>
                    <option value="self">Self</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="p-2 function-call" v-if="formData.tjType !== 'function'">
              <div class="functional-output__header">
                <h5>Selected Function: </h5>
              </div>
              <div class="p-2 funtional-output__body">
                <div class="p-2 functional-output__group">
                  <select v-model="attachedFunction">
                    <option :value="null">Select</option>
                    <option v-for="fn in availableFunctions" :key="fn.label" :value="fn">{{ fn.label }}</option>
                  </select>
                </div>
              </div>
            </div>
            </div>
            <!-- <div class="d-grid gap-2">
                <button class="p-2 ms-2 btn btn-sm btn-primary" @click="addPropField">Add New Property</button>
            </div> -->
          </div>
          <div class="modal-buttons">
            <button type="button" @click="onSubmit" class="ed-btn__blim ed-btn__sm modal-buttons__btn">Update</button>
            <button type="button" class="ed-btn ed-btn__sm modal-buttons__btn" @click="toggleEditMode">Close</button>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import IndexSelection from '@/common/components/IndexSelection.vue';
import graphReactor from '@/common/core/Graph/graphReactor';
import GraphEngine from '../../core/Graph/graphReactor/GraphEngine';

export default {
  props: {
    title: String,
    formData: Object,
    toggleEditMode: Function,
  },

  components: {
    IndexSelection,
  },
  async mounted() {
    if (this.formData.props) {
      this.propCount = Object.keys(this.formData.props).map((i) => [i, this.formData.props[i]]);
    }
    document.addEventListener('mousemove', (mouseMoveEvent) => {
      this.localPostition = {
        x: mouseMoveEvent.pageX,
        y: mouseMoveEvent.pageY,
      };
    }, false);
    document.querySelectorAll('#detailEditor .auto-textarea-input')[0].addEventListener('keydown', (e) => {
      if (e.keyCode === 51) {
        this.indexPostion = this.localPostition;
        this.toggleForm = true;
      } else {
        this.toggleForm = false;
      }
    });
    await this.fetchPrompts();
  },
  data() {
    return {
      quests: [],
      indexPostion: {},
      toggleForm: false,
      propCount: [],
      modeList: [
        'circle',
        'ellipse',
        'rect',
        'diamond',
        'triangle',
        'star',
        'image',
        'modelRect',
        'donut',
      ],
      color: '',
      customProps: {},
      shape: this.formData.type || '',
      size: this.formData.size,
      localPostition: {},
      attachedFunction: null,
      selectFunctionVariable: 'none',
      functionVariable: {
        intent: 'default',
      },
      functionalPrompt: '',
      functionalOutput: 'none',
      mavonToolbar: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: false, // 下划线
        strikethrough: false, // 中划线
        mark: false, // 标记
        superscript: false, // 上角标
        subscript: false, // 下角标
        quote: false, // 引用
        ol: false, // 有序列表
        ul: false, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: false, // code
        table: false, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: false, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: false, // 上一步
        redo: false, // 下一步
        trash: false, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: false, // 导航目录
        /* 2.1.8 */
        alignleft: false, // 左对齐
        aligncenter: false, // 居中
        alignright: false, // 右对齐
        /* 2.2.1 */
        subfield: false, // 单双栏模式
        preview: true, // 预览
      },
    };
  },
  watch: {
    attachedFunction(val) {
      console.log('xvf', val);
      // this.fetchNodeInfo();
    },
    color(val) {
      this.formData.style.fill = val.hex8.length > 0
        ? val.hex8 : this.formData.style.fill;
      // this.watchUpdate();
    },
    shape(val) {
      this.formData.type = val.length > 0 ? val : this.formData.type;
      this.formData.labelCfg.color = '#fff';
      this.formData.labelCfg = {
        position: 'top',
        color: '#fff',
        style: {
          fontSize: 15,
          fill: '#fff',
          fontWeight: 500,
          fontFamily: 'Poppins',
          color: '#fff',
        },
      };
      // this.watchUpdate();
    },
    size(val) {
      this.formData.size = val.length > 0 ? val : this.formData.size;
      // this.watchUpdate();
    },
    formData(val) {
      this.shape = val.type;
      this.formData.size = val.size || '20';
      this.color = {
        hex: '#194d33',
        hex8: val.style.fill,
        hsl: {
          h: 150,
          s: 0.5,
          l: 0.2,
          a: 1,
        },
        hsv: {
          h: 150,
          s: 0.66,
          v: 0.30,
          a: 1,
        },
        rgba: {
          r: 25,
          g: 77,
          b: 51,
          a: 1,
        },
        a: 1,
      };

      if (val.props) {
        this.propCount = Object.keys(val.props).map((i) => [i, val.props[i]]);
      } else {
        this.propCount = [];
      }

      if (this.formData.function) {
        console.log('xvf', this.formData);
        this.selectFunctionVariable = this.formData.function.variable;
        this.functionVariable = this.formData.function.value;
        this.functionalPrompt = this.formData.function.prompt;
        this.functionalOutput = this.formData.function.output;
      } else if (this.formData.attachedFunction) {
        this.attachedFunction = this.formData.attachedFunction || null;
      } else if (this.availableFunctions.length > 0) {
        this.attachedFunction = { ...this.availableFunctions[0] };
      }

      // eslint-disable-next-line no-param-reassign
    },
  },
  computed: {
    inputNodes() {
      const allNeighbours = graphReactor.exploreInstance.getNeighbors(this.formData.id, 'source');
      return allNeighbours;
    },
    availableFunctions() {
      const availableFunction = [];
      this.findFunctionNodes.forEach((node) => {
        // eslint-disable-next-line no-underscore-dangle
        if (node._cfg.model) {
          availableFunction.push({
            // eslint-disable-next-line no-underscore-dangle
            label: node._cfg.model.label,
            // eslint-disable-next-line no-underscore-dangle
            ...node._cfg.model.function,
          });
        }
      });
      return availableFunction;
    },
    findFunctionNodes() {
      const allNeighbours = graphReactor.exploreInstance.getNeighbors(this.formData.id, 'target');
      // console.log('xvf', 'nodeNeighbors', allNeighbours);
      // eslint-disable-next-line no-underscore-dangle
      const filterFunctionNode = allNeighbours.filter((node) => node._cfg.model.tjType === 'function');
      return filterFunctionNode;
    },
    prompts() {
      return this.$store.getters['quests/allPrompts'];
    },
  },
  methods: {
    async handleSearch(val) {
      console.log('search val', val);
      const responseGraphList = await this.$store.dispatch('quests/fetchQuestByKeyword', val);
      this.quests = responseGraphList;
    },
    addPropField() {
      this.propCount.push(['', '']);
    },
    async filterOption(input, option) {
      console.log(input, option);
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleChange(val) {
      // {{baseuri}}/api/v1/g6graph/query?type=quest_library&keyword=test use this keyword to filter and query
      const questMetaData = {
        id: val,
        label: this.quests.find(
          // eslint-disable-next-line no-underscore-dangle
          (element) => element._id === val,
        )
          .g6_data.graph_name,
      };
      this.formData.quest_meta_data = questMetaData;
      this.formData.style.fill = '#00c266';
      console.log(this.formData);
    },
    getQuery(val) {
      const indexT = document.querySelectorAll('#detailEditor .auto-textarea-input')[0].selectionStart;

      const finalCheck = `${this.formData.description.substr(0, indexT - 1)} \n$nl [${val.label}](${val.id}) nl$\n ${this.formData.description.substr(indexT)}`;
      this.formData.description = finalCheck;
      this.toggleForm = !this.toggleForm;
    },

    async fetchInfoFromOpenAI(query) {
      const detail = await this.$store.dispatch('fetchFromOpenAI', query);
      return detail;
    },

    createContext(fn, label) {
      const context = ` Context: {${label}}, Prompt: ${fn.prompt}`;
      return context;
    },

    async fetchNodeInfo() {
      const wordObj = {
        word: this.formData.id,
        label: this.formData.id,
        tjModel: this.formData,
        type: 'string',
        lang: this.$store.state.locale.currentLocale,
        context: this.createContext(this.attachedFunction, this.formData.id),
      };
      const nodeDescriptionInfo = await this.$store.dispatch('quests/fetchQuestNodeInfo', wordObj);
      console.log('xvf', nodeDescriptionInfo);
      this.formData.description.content = nodeDescriptionInfo;
    },

    async emitUpdateNode() {
      const response = await this.$store.dispatch('parseAndQueryFromOpenAI', {
        text: this.formData.description.content,
        splitter: 'eddie',
        callback: this.fetchInfoFromOpenAI,
      });

      if (response) this.formData.description.content = response;

      this.$refs.propsRef.childNodes.forEach((i) => {
        const ichild = i.childNodes;
        if (ichild[0].value.trim().length > 0) {
          this.customProps[ichild[0].value] = {
            type: 'custom',
            value: ichild[1].value,
          };
        }
      });

      this.formData.props = this.customProps;
      if (this.formData.tjType === 'function') {
        this.formData.function = {
          variable: this.selectFunctionVariable,
          value: this.functionVariable,
          prompt: this.functionalPrompt,
          output: this.functionalOutput,
        };
      } else {
        this.formData.attachedFunction = this.attachedFunction;
      }

      //
      const updatedNode = graphReactor.exploreInstance.findById(this.formData.id);
      // this.formData.style.fill = this.color.hex8;
      // this.formData.type = this.shape.length > 0 ? this.shape : this.formData.type;
      updatedNode.update(this.formData);
      await this.$store.dispatch('gmodule/saveG6ExploreData');
      graphReactor.exploreInstance.updateLayout();
    },
    async watchUpdate() {
      // this.$refs.propsRef.childNodes.forEach((i) => {
      //   const ichild = i.childNodes;
      //   this.customProps[ichild[0].value] = ichild[1].value;
      // });

      // this.formData.props = this.customProps;

      //
      const updatedNode = graphReactor.exploreInstance.findById(this.formData.id);
      this.formData.style.fill = this.color.hex8;
      this.formData.type = this.shape.length > 0 ? this.shape : this.formData.type;
      updatedNode.update(this.formData);
      await this.$store.dispatch('gmodule/saveG6ExploreData');
      graphReactor.exploreInstance.updateLayout();
    },
    async onSubmit() {
      await this.emitUpdateNode();
      this.toggleEditMode();
    },
    createNodeAsProperty(key, property) {
      // console.log('xvf', key, property);

      const nodes = property.value.split(',');
      console.log('xvf', nodes);
      let nodePayload;
      let edgePayload;
      if (nodes.length > 0) {
        nodes.forEach((node) => {
          if (node.length > 0) {
            nodePayload = {
              id: node,
              label: node,
              size: '30',
              style: {
                fontSize: 15,
                fill: '#fff',
                fontWeight: 500,
                fontFamily: 'Poppins',
                color: '#fff',
              },
            };
            edgePayload = {
              source: this.formData.label,
              target: nodePayload.label,
              label: key,
              style: {
                endArrow: true,
              },
            };
            GraphEngine.addVertex('explore', nodePayload);
            GraphEngine.addEdge('explore', edgePayload);
          }
        });
        graphReactor.exploreInstance.updateLayout();
      }
      // graphReactor.exploreInstance.add
    },
    convertAllInNodes(properties) {
      properties.forEach((property) => {
        this.createNodeAsProperty(property[0], property[1]);
      });
    },
    async fetchPrompts() {
      await this.$store.dispatch('quests/fetchAllPrompts');
    },
  },
};
</script>

<style lang="scss" scoped>
  .frm_heading{
    padding: 15px;
  }

  .addProps {
    padding: 0 10px;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
.input-group {
    padding: 10px;
}

.v-note-wrapper.shadow {
  z-index: 5!important;
}

.modal-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);

  &__btn {
    margin-right: .75rem;
  }
}

.node-properties {
  &__input {
    border-radius: .5rem;
    &:focus {
      outline: none;
    }
  }
}

.input-node {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__body {

  }
  &__form {
    display: flex;
    align-items: center;
  }
}
</style>
