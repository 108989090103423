<template>
  <div>
    <h1>Users</h1>
    <div class="button-container">
      <input v-model="username" placeholder="Enter username" class="input-wrap" />

      <button @click="applyUserFilter" class="year-button" :disabled="isLoading">
              <span v-if="!isLoading">Apply Filter</span>
              <span
                v-else
                class="spinner-border spinner-border-sm ml-2"
                :style="{ opacity: isLoading ? 1 : 0 }"
              ></span>
            </button>
      <button class="year-button" @click="showPastYearView">Past Year</button>
      <button class="year-button" @click="showPresentYearView">Present Year</button>
    </div>
    <div>
      <calendar-heatmap
        :end-date="datee"
        :values="finalDates"
        :style="{ 'max-width': '675px' }"
        :range-color="['#ebedf0', '#9be9a8', '#40c463', '#30a14e', '#216e39']"
        :max="1000"
        :round="2"
      ></calendar-heatmap>
    </div>
  </div>
</template>

<script>
import { CalendarHeatmap } from 'vue-calendar-heatmap';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    CalendarHeatmap,
  },
  computed: {
    ...mapState(['eventsData']),
  },
  data() {
    return {
      dates: [],
      finalDates: [],
      datee: new Date(),
      username: this.$store.state.auth.user.username,
      isLoading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['fetchEvents']),

    async showPastYearView() {
      const currentYear = new Date().getFullYear();
      this.datee = new Date(currentYear - 1, 0, 1);
      await this.fetchData(this.username);
    },

    async showPresentYearView() {
      this.datee = new Date();
      await this.fetchData(this.username);
    },

    async fetchData(username) {
      this.isLoading = true;
      try {
        await this.fetchEvents(username);
        this.processEvents();
      } catch (error) {
        console.log(error.message);
      } finally {
        this.isLoading = false;
      }
    },

    applyUserFilter() {
      this.fetchData(this.username);
    },

    processEvents() {
      try {
        this.dates = this.eventsData.map((event) => {
          const dateString = event.timestamp.machineReadable;
          const day = new Date(dateString);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(day)) {
            throw new Error(`Invalid date: ${dateString}`);
          }
          return day.toISOString().slice(0, 10);
        });

        setTimeout(() => {
          this.filterDates();
        }, 2000);
      } catch (error) {
        console.log('Error converting date:', error.message);
      }
    },

    filterDates() {
      const elementCounts = {};
      this.dates.forEach((element) => {
        elementCounts[element] = (elementCounts[element] || 0) + 1;
      });
      console.log(elementCounts);
      this.finalDates = Object.entries(elementCounts).map(([date, count]) => ({
        date,
        count,
      }));
    },
  },
};
</script>

<style>
.button-container {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}

.year-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 4px;
}

.year-button:hover {
  background-color: #45a049;
}

.year-button:focus {
  outline: none;
}

.year-button:active {
  background-color: #3e8e41;
}

input[type=text]:focus {
  border: 3px solid #555;
}
</style>
