<template>
  <ed-dropdown
    :selected="selectedPrompt"
    :listItems="prompts"
    :mapFunction="(prompt) => prompt.label"
    :isSelected="onChange"
    customClass="ed-dropdown__sm"
  />
</template>

<script>
import edDropdown from './ed-forms/ed-dropdown.vue';

export default {
  name: 'LanguageSelector',
  components: {
    edDropdown,
  },
  data() {
    return {
      selectedPrompt: 'Select A New Learning Intent',
    };
  },

  async mounted() {
    await this.fetchPrompts();
  },

  computed: {
    prompts() {
      return this.$store.getters['quests/allPrompts'];
    },
  },

  methods: {
    async fetchPrompts() {
      await this.$store.dispatch('quests/fetchAllPrompts');
    },
    onChange(tab) {
      const item = this.prompts.filter((prompt) => prompt.label === tab);
      this.$store.dispatch('quests/setActivePrompt', item[0]);
      this.selectedPrompt = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";
.lang-select {
  width: 5em !important;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  padding: 0.5em;
  border-radius: 5px;
  transition: all ease-in-out 200ms;

  &:hover {
    background-color: $edvantaButtonColor;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
</style>
