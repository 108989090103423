<template>
  <div>
    <div v-if="!editMode">
      <PromptTemplate v-if="questMode"/>
      <!-- Note Image -->
      <div
      v-if="
          detailOfSelected.tjType === 'image' ||
          detailOfSelected.tjType === 'note-image'
          "
      >
      <img :src="detailOfSelected.img" width="100%" height="100%" alt="" />
    </div>
    <!-- Custom Node -->
    <div v-else-if="detailOfSelected.tjType === 'custom-node'">
        <div
          class="d-flex justify-content-between wikiDescription__heading"
        >
          <h4>{{ detailOfSelected.label }}</h4>

          <div class="d-flex mx-1">
            <i
              title="Edit Mode"
              class="box-icon box-icon--effect details-controllers-margin bx bx-edit-alt"
              @click="toggleEditMode"
            />
             <i
                title="copy to clipboard"
                class="box-icon box-icon--effect details-controllers-margin bx bx-link"
                id="clipboard_icon"
                @click="copyClipboard(detailOfSelected.description.content)"
              />

                <i
                title="add to note"
                class="box-icon box-icon--effect details-controllers-margin bx bx-note"
                id="note_icon"
                @click="addToNote(detailOfSelected)"
              />
          </div>
        </div>
        <div v-if="detailOfSelected.additionalDescription" v-html="detailOfSelected.additionalDescription"></div>
        <div  v-if="detailOfSelected.quest_meta_data">
            <hr v-if="detailOfSelected.additionalDescription"/>
            <a-tag
            style="width: 100%; text-align: center; cursor: pointer"
            @click="setCurrentQuestGraph(detailOfSelected.quest_meta_data)"
            :title="detailOfSelected.quest_meta_data.label" color="blue">
              Quest: {{ getTrimmedText(detailOfSelected.quest_meta_data.label) }}
            </a-tag>
        </div>
        <hr v-if="detailOfSelected.additionalDescription && detailOfSelected.description"/>
        <h5 v-if="detailOfSelected.additionalDescription  && detailOfSelected.description" v-html="contentDescription(`About ${detailOfSelected.label}` || '')"></h5>
        <TextAnalysis
          v-if="detailOfSelected.description"
          :content="detailOfSelected.description"
          :label="label"
          Mode="Default"
        />
      </div>
      <!-- Function Node -->
      <div v-else-if="detailOfSelected.tjType === 'function' || detailOfSelected.tjType === 'openai_logic_node'">
        <div
          class="d-flex justify-content-between wikiDescription__heading"
        >
         <h4>{{ detailOfSelected.label }}</h4>

          <div class="d-flex mx-1">
            <i
              title="Edit Mode"
              class="box-icon box-icon--effect details-controllers-margin bx bx-edit-alt"
              style="margin-left: auto;"
              @click="toggleEditMode"
            />
             <i
                title="copy to clipboard"
                class="box-icon box-icon--effect details-controllers-margin bx bx-link"
                id="clipboard_icon"
                @click="copyClipboard(detailOfSelected.additionalDescription)"
              />

                <i
                title="add to note"
                class="box-icon box-icon--effect details-controllers-margin bx bx-note"
                id="note_icon"
                @click="addToNote(detailOfSelected)"
              />
          </div>
        </div>
        <div v-if="detailOfSelected.additionalDescription" v-html="detailOfSelected.additionalDescription"></div>
        <div  v-if="detailOfSelected.quest_meta_data">
           <hr/>
            <a-tag
            style="width: 100%; text-align: center; cursor: pointer"
            @click="setCurrentQuestGraph(detailOfSelected.quest_meta_data)"
            :title="detailOfSelected.quest_meta_data.label" color="blue">
              Quest: {{ getTrimmedText(detailOfSelected.quest_meta_data.label) }}
            </a-tag>
        </div>
        <hr v-if="detailOfSelected.additionalDescription"/>
        <h5 v-if="detailOfSelected.additionalDescription" v-html="contentDescription(`About ${detailOfSelected.label}` || '')"></h5>
        <!-- <TextAnalysis
          v-if="detailOfSelected.additionalDescription"
          :content="detailOfSelected.additionalDescription"
          :label="label"
          Mode="custom"
          /> -->
          <TextAnalysis
            :content="detailOfSelected.description"
            :label="label"
            Mode="Sentences"
          />
      </div>
      <!-- Note Text -->
      <div v-else-if="detailOfSelected.tjType === 'note-text'">
        <div class="px-1 py-3">
          <div
            class="d-flex justify-content-between wikiDescription__heading"
          >
            <h4>{{ detailOfSelected.label }}</h4>

            <div class="d-flex">
              <i
                v-if="showEditMode"
                title="Edit Mode"
                class="box-icon box-icon--effect details-controllers-margin bx bx-edit-alt"
                @click="toggleEditMode"
                  style="margin-left: auto;"
              />
              <TextToSpeech
                :speech="detailOfSelected.description.content"
                :label="label"
              />
            </div>
          </div>
          <div  v-if="!questMode">
            <TextAnalysis :content="detailOfSelected.description" Mode="custom" />
          </div>
          <div v-else>
             I am ready to serve. Search something now.
          </div>

        </div>
        <div>

          <li v-for="(value, prop) in detailOfSelected.props" :key="prop">
            {{ prop }} - {{ value }}
          </li>
        </div>
      </div>
      <!-- Note Video -->
      <div
        v-else-if="detailOfSelected.tjType === 'note-video'"
        class="ratio ratio-16x9"
      >
        <iframe
          :src="
            videoEmbedURLFromURL(
              detailOfSelected.description.content ||
                detailOfSelected.note ||
                detailOfSelected.note
            )
          "
          allowfullscreen
        ></iframe>
      </div>
      <!-- Note Recording -->
      <div v-else-if="detailOfSelected.tjType === 'note-recording'">
        <video width="100%" height="100%" controls>
          <source
            :src="detailOfSelected.description.content || detailOfSelected.note"
            type="video/mp4"
          />
        </video>
      </div>
      <!-- Note 3d -->
      <div
        v-else-if="detailOfSelected.tjType === 'note-3d'"
        v-html="detailOfSelected.tjData.content"
      ></div>
      <!-- Note Page -->
      <div v-else-if="detailOfSelected.tjType === 'note-page'">
        <div
          class="d-flex justify-content-between wikiDescription__heading"
        >
          <h4 v-html="detailOfSelected.label"></h4>
          <div class="d-flex align-items-center">
            <div class="btn"></div>
            <div class="d-flex">
              <i
                @click="addNoteToGraph(detailOfSelected)"
                name="export"
                class="box-icon box-icon--effect bx bx-export details-controllers-margin"
              ></i>
              <i
                title="Edit Mode"
                class="bx bx-edit-alt box-icon box-icon--effect details-controllers-margin"
                @click="toggleEditMode"
              />
            </div>
          </div>
        </div>
        <div class="px-1 py-3" v-if="detailOfSelected.tjData">
          <h6>Source:</h6>
          <a
            class="px-1 py-3"
            v-if="detailOfSelected.tjData.url"
            :href="detailOfSelected.tjData.url"
            target="_blank"
            >
            <i
              class="bx bx-link-external box-icon box-icon--effect"
              @click="detailOfSelected.tjData.url"
            ></i
          ></a>
          <a class="px-1 py-3" v-else href="">No Link Available</a>
        </div>
      </div>
      <div v-else-if="detailOfSelected['tjType'] === 'openai_node'">
        <div class="px-1 py-3">
          <div
            class="d-flex justify-content-between wikiDescription__heading"
          >
            <h4>{{ detailOfSelected.label }}</h4>

            <div class="d-flex">
              <i
                title="Things To Read"
                :class="{ clicked: $store.state.node_info_section.article }"
                class="box-icon box-icon--effect details-controllers-margin bx bx-spreadsheet"
                id="things_to_read"
                @click="toggleOption('article')"
                style="margin-left: auto;"
              />
              <i
                title="Things To Answer"
                :class="{ clicked: $store.state.node_info_section.questions }"
                class="box-icon box-icon--effect details-controllers-margin bx bx-transfer"
                id="things_to_answer"
                @click="toggleOption('questions')"
              />
              <i
                title="youtube"
                :class="{ clicked: $store.state.node_info_section.video }"
                class="box-icon box-icon--effect details-controllers-margin bx bxl-youtube"
                id="youtube_icon"
                @click="toggleOption('video')"
              />
               <i
                title="copy to clipboard"
                class="box-icon box-icon--effect details-controllers-margin bx bx-link"
                id="clipboard_icon"
                @click="copyClipboard(detailOfSelected.description)"
              />

                <i
                title="add to note"
                class="box-icon box-icon--effect details-controllers-margin bx bx-note"
                id="note_icon"
                @click="addToNote(detailOfSelected)"
              />

              <TextToSpeech
                :speech="convertToPlainText(detailOfSelected.description)"
                :label="label"
              />
            </div>
          </div>
          <div v-html="detailOfSelected.description"></div>
        </div>
      </div>
      <div class="px-1 py-3" v-else>
        <div
          class="d-flex justify-content-between wikiDescription__heading"
        >
          <h4>{{ detailOfSelected.label }}</h4>
          <div class="d-flex">
            <div class="btn"></div>
            <div class="d-flex" style="margin-left: auto;">
              <i
                @click="addNoteToGraph(detailOfSelected)"
                name="export"
                class="box-icon box-icon--effect bx bx-export details-controllers-margin"
              ></i>
              <TextToSpeech :speech="detailOfSelected.description.content" />
              <i
                v-if="showEditMode"
                title="Edit Mode"
                class="bx bx-edit-alt box-icon box-icon--effect details-controllers-margin"
                @click="toggleEditMode"
              />
               <i
                title="copy to clipboard"
                class="box-icon box-icon--effect details-controllers-margin bx bx-link"
                id="clipboard_icon"
                @click="copyClipboard(detailOfSelected.description.content)"
              />

                <i
                title="add to note"
                class="box-icon box-icon--effect details-controllers-margin bx bx-note"
                id="note_icon"
                @click="addToNote(detailOfSelected)"
              />
            </div>
          </div>
        </div>
        <TextAnalysis
          :content="detailOfSelected.description"
          Mode="Sentences"
        />
      </div>
      <div class="px-1 py-3" v-if="detailOfSelected.tjData">
        <h6>Source:</h6>
        <a
          class="px-1 py-3"
          v-if="detailOfSelected.tjData.url"
          :href="detailOfSelected.tjData.url"
          target="_blank"
        >
          <i
            class="bx bx-link-external noteItem-preference__icon box-icon box-icon--effect"
            @click="detailOfSelected.tjData.url"
          ></i
        ></a>
        <a class="px-1 py-3" v-else href="">No Link Available</a>
      </div>
    </div>
    <div v-else>
      <NodeForm
        customId="nodeOperation"
        msgData="Hello from G6 Explore"
        title="G6 Explore Node"
        :toggleEditMode="toggleEditMode"
        :formData="formDataPayload"
      />
    </div>
    <hr v-if="questMode" class="ed-hr"/>
    <p v-if="questMode" class="wikiDescription__disclaimer open-source__source-text px-1">

      <b>PS:</b> Just like you I'm too improving daily in my learning journey. Use the information I shared wisely, considering that responses may not always be entirely accurate.
    </p>
  </div>
</template>

<script>
import marked from 'marked';
import moment from 'moment';
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
// import { uniqueIdGenerate } from '@/utils/reuse';
// eslint-disable-next-line
import graphReactor from "@/common/core/Graph/graphReactor";
import NodeForm from '@/common/widgets/DetailWidget/NodeForm.vue';
import TextToSpeech from '@/common/components/TextToSpeech.vue';
import TextAnalysis from '@/common/components/TextAnalysis.vue';
import removeHTMLTags from '@/utils/removeHTMLTags';
import PromptTemplate from '@/common/components/PromptTemplate.vue';

export default {
  props: {
    showEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: {
        tjType: 'note-text',
        description: {
          content: "You haven't selected a node yet.",
        },
        tjData: null,
      },
      label: 'details',
      editMode: false,
      nodeInfoSection: {
        video: true,
        questions: true,
        article: true,
      },
    };
  },
  methods: {
    toggleOption(section) {
      this.nodeInfoSection[section] = !this.nodeInfoSection[section];
      this.$store.dispatch('updateNodeInfoSection', this.nodeInfoSection);
    },

    copyClipboard(payload) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = payload;

      // Get the text content without HTML tags
      const plainText = tempElement.textContent || tempElement.innerText;

      // Copy the plain text to the clipboard
      navigator.clipboard.writeText(plainText);
    },

    addToNote(payload) {
      this.isLoading = true;
      const value = {
        note: payload.description.content ? payload.description.content : payload.description,
        noteTitle: payload.id,
        noteUrl: 'thoughtjumper-client.netlify.app/',
        type: 'note',
        timestamp: moment().format(),
      };

      this.$store.dispatch('addToNote', value).then(() => {
        this.customNote = '';
        this.isLoading = false;
      });
    },

    async setCurrentQuestGraph({ id, label }) {
      const graphObj = {
        graphId: id,
        label,
        type: 'graph',
        questLibrary: true,
      };
      this.$store.dispatch('setCurrentThought', {
        thoughtObject: graphObj,
        excludeCallList: ['quests/questGraphAction'],
      });

      this.$store.dispatch('toggleQuestModeWithPayload', true);

      console.log(`Fetching graph... with id ${id}`);
    },
    getTrimmedText(str) {
      return `${str.split('').slice(0, 30).join('')}...`;
    },
    async addNoteToGraph(payload) {
      // kaaro: The following logic to moved to actions. We have a similar action that can be made more generic for this (addYoutubeToGraph)
      const item = {
        id: payload.id,
        value: {
          type: 'note',
          note: payload.description,
          noteUrl: 'https://app.thoughtjumper.com',
        },
      };
      await exploreOps.addNoteToGraph(
        item,
        this.$store.getters['auth/authUser'],
      );

      graphReactor.exploreInstance.addItem('edge', {
        source: item.id,
        target: item.label,
        label: 'has note',
        style: {
          endArrow: true,
        },
      });

      await this.$store.dispatch('gmodule/saveG6ExploreData');
      graphReactor.exploreInstance.updateLayout();
      this.addNoteInLibrary(payload.description);
    },

    addNoteInLibrary(note) {
      const value = {
        note,
        noteUrl: 'https://app.thoughtjumper.com',
        type: 'note',
        timestamp: moment().format(),
      };

      const verb = {
        display: {
          en: 'NOTED',
        },
      };

      const data = {
        verb,
        value,
        dataSource: 'tj-client',
      };

      this.$store.dispatch('addToNote', data);
    },
    videoEmbedURLFromURL(url) {
      if (url) {
        const regex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
        const match = url.match(regex);
        if (match && match[1].length === 11) {
          return `https://www.youtube.com/embed/${match[1]}?rel=0`;
        }
      }
      return '';
      // ^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*
    },

    // ! Deprecated
    contentDescription(content) {
      return marked(content);
    },

    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    convertToPlainText(payload) {
      return removeHTMLTags(payload);
    },
  },

  created() {
    this.$highway.singleDispatcher('updateDetailPayload');
  },

  computed: {
    detailOfSelected() {
      console.log('detailOfSelected', this.$store.state.payload_detail);
      return this.$store.state.payload_detail;
    },
    formDataPayload() {
      const formData = this.detailOfSelected;
      if (formData.tjType === 'wikiLinks') {
        formData.extra = formData.description;
        // formData.description = formData.description;
        formData.tjType = 'custom-node';
        formData.size = 30;
      }
      return formData;
    },
    questMode() {
      return this.$store.state.quests.quest_mode;
    },
  },
  components: {
    NodeForm,
    TextToSpeech,
    TextAnalysis,
    PromptTemplate,
  },
};
</script>

<style lang="scss" scoped>
.green-icon {
  background-color: green;
  color: green;
}

#things_to_read.clicked {
  color: black; /* Change the color to highlight */
}
#things_to_answer.clicked {
  color: black; /* Change the color to highlight */
}
#youtube_icon.clicked {
  color: black; /* Change the color to highlight */
}
.details-controllers-margin {
  margin-right: 0.5rem;
}
.yt-vid {
  max-width: 700px;
  height: auto;
}

.ytBed {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wikiDescription__heading{
  flex-direction: column;
}
</style>
