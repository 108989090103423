<template>
  <div class="container">
    <div id="table">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="link in links" :key="link.value.id">
            <td class="w-50">
              <a target="_blank" class="link_visit" :href="link.value.url">{{
                link.value.title === "" ? "NO title" : link.value.title
              }}</a>
            </td>
            <td scope="w-50">{{ link.timestamp.humanReadable }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- {{links}} -->
  </div>
</template>

<script>
export default {
  name: 'SkyView',

  sockets: {
    newNotification(data) {
      if (data.verb.display.en === 'VISITED') {
        // this.linksDumpFetch();
        this.links.unshift(data);
      }
    },
  },

  methods: {
    async linksDumpFetch() {
      this.links = await this.$store.dispatch('FetchVisitedByUser');
    },
  },

  data() {
    return {
      links: [],
    };
  },
  async mounted() {
    this.links = await this.$store.dispatch('FetchVisitedByUser');
  },
};
</script>

<style lang='scss' scoped>
#table {
  width: auto;
  height: 300px;
}
.link_visit {
  text-decoration: underline !important;
}
th {
  background-color: rgb(240, 228, 173);
  border: 2px rgb(236, 124, 124);
}
</style>
