<template>
  <div class="container">

<div class="d-flex">
      <div>
        <span class="ed-label">From: </span>
        <datepicker
          placeholder="Select Date"
          v-model="selectedDate"
        ></datepicker>
      </div>
      <div class="date-picker-container">
        <span class="ed-label">To: </span>
        <datepicker
          placeholder="Select Date"
          v-model="selectedEndDate"
        ></datepicker>
      </div>
      <div class="user-filter">
        <i
          class="box-icon box-icon--effect bx bx-user-circle"
          :class="{ 'box-icon--active': showMyGraphs }"
          :title="showMyGraphs ? 'Show All Graphs' : 'Show My Graphs'"
          @click="toggleShowGraphs"
        />
      </div>
    </div>

    <div class="banner">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="header-text caption">
              <div class="word-chart" ref="chartdiv"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
// eslint-disable-next-line camelcase
import * as am4plugins_wordCloud from '@amcharts/amcharts4/plugins/wordCloud';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
export default {
  name: 'SkyView',

  components: {
    Datepicker,
  },

  async mounted() {
    await this.fetchWordData({
      date: this.selectedDate,
      user: this.categories[0],
    });
    await this.buildWordCloud();
  },

  data() {
    return {
      wordData: null,
      showMyGraphs: false,
      selectedDate: new Date().getTime(),
      selectedEndDate: new Date().getTime(),
      selectedCategories: '@me',
      categories: ['@all', '@me'],
    };
  },

  watch: {
    selectedDate(newDate) {
      console.log('new Date: ', newDate);
      moment(newDate).format();
      this.fetchWordData({
        date: this.selectedDate,
        endDate: this.selectedEndDate,
        user: this.selectedCategories,
      }).then(() => {
        this.chart.data = this.wordData;
      });
    },

    selectedEndDate(newEndDate) {
      console.log('new Date: ', newEndDate);
      moment(newEndDate).format();
      this.fetchWordData({
        date: this.selectedDate,
        endDate: this.selectedEndDate,
        user: this.selectedCategories,
      }).then(() => {
        this.chart.data = this.wordData;
      });
    },
    showMyGraphs(flag) {
      if (flag) {
        this.fetchWordData({
          user: this.categories[1],
          date: this.selectedDate,
          endDate: this.selectedEndDate,
        }).then(() => {
          this.chart.data = this.wordData;
        });
      } else {
        this.fetchWordData({ user: this.categories[0] }).then(() => {
          this.chart.data = this.wordData;
        });
      }
    },
    selectedCategories(user) {
      this.fetchWordData({
        date: this.selectedDate,
        endDate: this.selectedEndDate,
        user,
      }).then(() => {
        this.chart.data = this.wordData;
      });
    },
  },

  methods: {
    async fetchWordData(payload) {
      const res = await this.$store.dispatch('mostSearchedWord', payload);
      this.wordData = res;
    },

    toggleShowGraphs() {
      this.showMyGraphs = !this.showMyGraphs;
    },

    async buildWordCloud() {
      // eslint-disable-next-line no-undef
      const chart = am4core.create(
        this.$refs.chartdiv,
        am4plugins_wordCloud.WordCloud,
      );
      chart.fontFamily = 'Courier New';
      // eslint-disable-next-line no-undef
      const series = chart.series.push(
        new am4plugins_wordCloud.WordCloudSeries(),
      );
      series.randomness = 0.1;
      series.rotationThreshold = 0.5;

      chart.data = this.wordData;
      series.dataFields.word = 'word';
      series.dataFields.value = 'value';

      series.heatRules.push({
        target: series.labels.template,
        property: 'fill',
        min: am4core.color('#0000CC'),
        max: am4core.color('#CC00CC'),
        dataField: 'value',
      });

      series.labels.template.url = 'https://en.wikipedia.org/wiki/{word}';
      series.labels.template.urlTarget = '_blank';
      series.labels.template.tooltipText = '{word}: {value}';

      const hoverState = series.labels.template.states.create('hover');
      hoverState.properties.fill = am4core.color('#FF0000');

      this.chart = chart;
    },
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style lang='scss' scoped>
.word-chart {
  margin-top: 0.8rem;
  width: 100%;
  height: 300px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#chartdiv {
  width: 100%;
  height: 300px;
}
.header-text caption {
  text-align: center;
}
.user-filter{
 margin-top: 1.9rem;
 margin-left: auto;
}
.date-picker-container{
  margin-left: 1rem
}
</style>
