<template>
  <div class="d-flex flex-column">
    <h3 class="header">My Analytics</h3>
    <hr/>
    <form class="inputs my-3 d-flex align-items-center justify-content-between">
      <input type="text" v-model="users" class="analytics__input ed-input p-2" placeholder="Use ',' for multiple Users ">
      <button type="button" class="ed-btn__blim" @click="fetchAnalytics()">Filter by Users</button>
    </form>
    <div class="results" v-html="analytics"></div>
  </div>
</template>

<script>
export default {
  name: 'UserAnalyticsWidget', // Adjusted the component name to follow the recommended naming convention
  mounted() {
    this.fetchAnalytics();
  },
  data() {
    return {
      users: [], // Initialize as an empty array to store user analytics data
    };
  },
  computed: {
    analytics() {
      return this.$store.state.userAnalytics;
    },
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.fetchAnalytics();
    },
    fetchAnalytics() {
      // You may want to pass other parameters along with 'users' here
      this.$store.dispatch('fetchUserAnalytics', { users: this.users });
    },
  },
};

</script>

<style>

</style>
