<template>
  <div class="index-selection" :style="dynamicPositionStyle(position)">
    <!-- form input and list -->
    <div class="input-wrap">
      <input
        placeholder="Search for the node you want to link:"
        v-model="searchIndex"
        type="text"
      />
    </div>
    <ul class="list-wrap">
      <li
        @click="emitDetail(item)"
        class="list-item"
        :id="item.id"
        :label="item.label"
        v-for="item in listItems"
        :key="item"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchIndex: '',
      listItems: [],
    };
  },

  mounted() {
    this.listItems = this.graphData.nodes.map((node) => ({
      label: node.label,
      id: node.id,
    }));
  },

  computed: {
    graphData() {
      return this.$store.state.gmodule.g6_explore_data;
    },
  },

  watch: {
    graphData(val) {
      this.listItems = val.nodes.map((node) => ({
        label: node.label,
        id: node.id,
      }));
    },
    searchIndex(val) {
      this.listItems = this.graphData.nodes.map((node) => ({
        label: node.label,
        id: node.id,
      }));
      this.listItems = this.listItems.filter((item) => item.label.toLowerCase().includes(val.toLowerCase()));
    },
  },

  methods: {
    dynamicPositionStyle(ps) {
      return {
        position: 'fixed',
        top: `${ps.y}px`,
        left: `${ps.x}px`,
        width: '200px',
        height: '100px',
        zIndex: '1000',
        overflowX: 'hidden',
        overflowY: 'scroll',
        backgroundColor: '#fff',
      };
    },

    emitDetail(payload) {
      this.$emit('queryDetail', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";
.list-wrap {
  list-style-type: none;
}

.list-item {
  padding: 4px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.655);
    cursor: pointer;
    color: #659dbd !important;
  }
}

.index-selection {
  input {
    width: 100%;
  }
  padding: 2px;
  border-radius: 5px;
  border: 1px solid #ccc;
  &::-webkit-scrollbar-track {
    background: $lightGray;
    // border-left: 5px solid $accentYellow;
    border-left: none;
  }
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0px;
  }
}
</style>
