<template>
  <div class="overflow">
    <button v-for="componentDetail in allComponentList" :key="componentDetail.name" @click="emitComponent(componentDetail.name)">{{componentDetail.options.title}}</button>
  </div>
</template>

<script>
export default {
  props: ['allComponentList'],
  methods: {
    emitComponent(componentName) {
      this.$emit('emitComponent', componentName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/abstracts/variables";

button {
  margin: 0.1875em;
}
.lang-select {
  width: 15.5em !important;
  text-transform: uppercase;
  padding: 0.5em;
  transition: all ease-in-out 200ms;

  &:hover {
    background-color: $edvantaButtonColor;
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.overflow {
    overflow: auto;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
</style>
