<template>
    <div id="ed-mainframe">
        <box-icon class="fullScreen-Icon" @click="makeScreen" name='fullscreen'></box-icon>
         <Layout  :resize="true" :edit="true" :splits="tree">
            <Pane class="split-layout-custom-style">
                <GraphIndex :currentSelectedEntity="this.$route.query.node"/>
            </Pane>
            <Pane class="split-layout-custom-style">
                <GraphExplore/>

            </Pane>
            <Pane class="split-layout-custom-style">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="detail-tab" data-bs-toggle="tab" data-bs-target="#detail" type="button" role="tab" aria-controls="detail" aria-selected="true">Detail</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video" type="button" role="tab" aria-controls="video" aria-selected="false">Videos</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="detail" role="tabpanel" aria-labelledby="detail-tab">
                  <Detail :showEditMode="false" />
                </div>
                <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
                  <Videos/>
                </div>
              </div>
            </Pane>
        </Layout>
    </div>
</template>

<script>
import { Layout, Pane } from 'vue-split-layout';

import GraphExplore from '@/common/widgets/GraphExploreWidget/GraphExploreWidget.vue';
import Detail from '@/common/widgets/DetailWidget/DetailWidget.vue';
import Videos from '@/common/widgets/VideosWidget/VideosWidget.vue';
import GraphIndex from '@/common/widgets/GraphIndexWidget/GraphIndexWidget.vue';

export default {
  components: {
    GraphExplore,
    Detail,
    GraphIndex,
    Videos,
    Layout,
    Pane,
  },
  data() {
    return {
      tree: {
        dir: 'horizontal',
        first: 0,
        second: {
          dir: 'vertical', // Left | Right
          // Other split
          first: 1,
          second: 2,
          split: '60%',
        },
        split: '20%',
      },

      layoutKeys: ['_a', '_b', '_c'],
    };
  },
  methods: {
    makeScreen(el) {
      if (document.fullscreen) {
        document.exitFullscreen();
      } else {
        console.log(el);
        el.target.parentElement.requestFullscreen();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.fullScreen-Icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 6;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
}
.split-layout-custom-style .content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
#ed-mainframe {
  position: relative;
  height: 100vh;
}
</style>
