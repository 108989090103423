<template>
  <div id="workBench">
    <EmitComponent :allComponentList="allComponentList" @emitComponent="addComponent" />
    <!-- bootstrap toggle button -->
    <div class="form-check form-switch ml-2">
      <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="connected">
      <label class="form-check-label" for="flexSwitchCheckChecked">Connected Components</label>
    </div>
    <div ref="root_node" id="branchPaint"></div>
  </div>
</template>

<script>
import { Graph } from '@antv/x6';
import Vue from 'vue';

import AbilityMixin from '@/mixins/AbilityMixin';
import EmitComponent from '@/common/components/EmitComponent.vue';

// eslint-disable-next-line
import router from '@/router';
import canvasConfig from '@/config/canvasConfig';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line
import store from '../store';
// eslint-disable-next-line
import i18n from '../i18n';
import {
  coreComponentOptions,
} from '../config/componentLoader';

export default {
  name: 'PageCanvas',

  metaInfo: {
    title: 'Canvas View',
  },

  components: {
    EmitComponent,
  },
  mixins: [AbilityMixin],

  computed: {
    allComponentList() {
      return coreComponentOptions
        .filter((widget) => this.abilities.can('load', widget.name));
    },
  },

  data() {
    return {
      connected: false,
    };
  },
  async mounted() {
    await this.buildMyPage();
    // Shorcut Keys
    this.bindKeys();
    // edit label of an edge
    this.graph.on('edge:dblclick', this.addEdgeLabel);
  },

  methods: {
    // add labels to your edge
    addEdgeLabel({ cell, e }) {
      cell.addTools({
        name: 'edge-editor',
        args: {
          event: e,
        },
      });
    },

    disableInt() {
      this.graph.disablePanning();
      this.graph.lockScroller();
      this.graph.disableMouseWheel();
    },

    bindKeys() {
      // delete
      this.graph.bindKey('ctrl+backspace', () => {
        const cells = this.graph.getSelectedCells();
        if (cells.length) {
          this.graph.removeCells(cells);
        }
      });

      // to undo things
      this.graph.bindKey(['meta+z', 'ctrl+z'], () => {
        if (this.graph.history.canUndo()) {
          this.graph.history.undo();
        }
        return false;
      });

      // to redo things
      this.graph.bindKey(['meta+shift+z', 'ctrl+shift+z'], () => {
        if (this.graph.history.canRedo()) {
          this.graph.history.redo();
        }
        return false;
      });

      // to select all nodes
      this.graph.bindKey(['meta+a', 'ctrl+a'], () => {
        const nodes = this.graph.getNodes();
        if (nodes) {
          this.graph.select(nodes);
        }
      });
    },

    async addComponent(componentName) {
      // Generate random id to use for the new component
      const id = `V${Math.random().toString(36).substr(2, 9)}`;
      // wrap.style.borderRadius = '0px';
      // Now simply add newNode and put your div element inside it - Now you have a div with the id on which we will mount our new component or instance
      // const newNode = this.graph.addNode({
      //   shape: 'html',
      //   x: Math.random() * 700, // to prvent overlap - I added this to place new node at random position
      //   y: Math.random() * 700,
      //   width: 200,
      //   height: 70,
      //   html: () => {
      //     // create div element and add classname so that we can edit its outer style
      //     const wrap = document.createElement('div');
      //     wrap.classList.add('nodeEl');
      //     // A div element with id that we created - we will mount our new component or instance here.
      //     wrap.style.borderRadius = '40px';
      //     return wrap;
      //   },
      // });
      const newNode = this.graph.addNode({
        shape: 'html',
        x: Math.random() * 200, // to prvent overlap - I added this to place new node at random position
        y: Math.random() * 200,
        width: 500,
        height: 500,
        html: () => {
          const wrap = document.createElement('div');
          wrap.classList.add('nodeEl');
          wrap.innerHTML = `<div class="nodeEl__title-bar"><i class="bx bx-x box-icon nodeEl__close"></i></div><div id="${id}" class="nodeEl__container"></div>`;
          return wrap;
        },
      });
      document.querySelectorAll('.nodeEl__close').forEach((el) => {
        el.addEventListener('click', () => {
          const cells = this.graph.getSelectedCells();
          console.log('pageCanvas', this.graph);
          if (cells.length) {
            this.graph.removeCells(cells);
          }
        });
      });
      // As we now its listening emit event
      coreComponentOptions.forEach((obj) => {
        // Loop and store the component which we will mount on our new instance
        if (obj.name === componentName) {
          this.currentComponent = obj.component;
        }
      });

      // One edge for each component where it will be connected with main node [Although i think it will be optional]
      if (this.connected) {
        this.graph.addEdge({
          source: this.source,
          target: newNode,
        });
      }
      // create new vue instance with store and pass the id of the div element to mount our component on
      const newInstance = new Vue({
        router,
        store,
        i18n,
        render: (h) => h(this.currentComponent),
      });
      newInstance.$mount(`#${id}`);
      // now launch the dispatcher with current_selected_entity
      this.dispatchCurrentEntity();
    },

    dispatchCurrentEntity() {
      const { word } = store.state.current_selected_entity;
      const wordObj = {
        word, label: word, type: 'string', lang: 'en',
      };
      this.$store.commit('SET_CURRENT_ENTITY', wordObj);
    },

    async buildMyPage() {
      this.config = canvasConfig;
      this.config.container = this.$refs.root_node;
      this.config.width = document.getElementById('workBench').clientwidth;
      this.config.height = document.getElementById('workBench').clientheight;
      this.graph = new Graph(this.config);

      // 使用文档：https://x6.antv.vision/zh/docs/tutorial/advanced/react#%E6%B8%B2%E6%9F%93-html-%E8%8A%82%E7%82%B9
      if (this.connected) {
        this.source = this.graph.addNode({
          shape: 'html',
          x: 120,
          y: 50,
          width: 120,
          height: 50,
          html: () => {
            const wrap = document.createElement('div');
            wrap.style.width = '100%';
            wrap.style.height = '100%';
            wrap.style.display = 'flex';
            wrap.style.alignItems = 'center';
            wrap.style.justifyContent = 'center';
            wrap.style.border = '2px solid #9254de';
            wrap.style.background = '#efdbff';
            wrap.style.borderRadius = '40px';
            wrap.innerText = 'Components';
            return wrap;
          },
        });
      }
    },
  },

};
</script>

<style lang="scss" scoped>
#workBench {
  width: 100%;
  height: 100vh;
}

#branchPaint {
  width: 100%;
  height: 100%;
}
</style>
