<template>
  <div ref="graphIndexRef" id="graph_index">
     <div class="example-header mb-3">
          <!-- <span v-if="isActiveDetail === 'Nodes'">Node Filter:</span>
          <span v-if="isActiveDetail === 'Edges'">Edge Filter:</span>
          <span v-if="isActiveDetail === 'Combos'">Combo Filter:</span>
          <span v-if="isActiveDetail === 'Props'">Properties Filter:</span> -->
          <div class="ed-input">
            <input  class="ed-input__field" type="text" id="filter-text-box"
            placeholder="Quick Filter"
            v-model="quickSearchParam"
            @input="onFilterTextBoxChanged()">
          </div>
      </div>
    <!-- {{displayNodes}} -->
    <ul class="nav nav-tabs nav-overflow" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="graphIndex-nodes-tab"
            data-bs-toggle="tab"
            data-bs-target="#graphIndex-nodes"
            type="button"
            role="tab"
            aria-controls="graphIndex-nodes"
            aria-selected="true"
            @click="setActiveDetail('Nodes')"
          >
            Nodes
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="graphIndex-combos-tab"
            data-bs-toggle="tab"
            data-bs-target="#graphIndex-combos"
            type="button"
            role="tab"
            aria-controls="graphIndex-combos"
            aria-selected="false"
            @click="setActiveDetail('Combos')"
          >
            Categories
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="graphIndex-edges-tab"
            data-bs-toggle="tab"
            data-bs-target="#graphIndex-edges"
            type="button"
            role="tab"
            aria-controls="graphIndex-edges"
            aria-selected="false"
            @click="setActiveDetail('Edges')"
          >
            Relationships
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="graphIndex-edges-tab"
            data-bs-toggle="tab"
            data-bs-target="#graphIndex-edges"
            type="button"
            role="tab"
            aria-controls="graphIndex-edges"
            aria-selected="false"
            @click="setActiveDetail('Props')"
          >
            Properties
          </button>
        </li>
    </ul>
    <div class="tab-content" id="graphIndexTabContent"></div>
    <!-- <div class="switches">
      <div @click="setActiveDetail('Nodes')" class="switches__btn" :class="{ active: isActiveDetail === 'Nodes' }">Nodes</div>
      <div @click="setActiveDetail('Combos')" class="switches__btn" :class="{ active: isActiveDetail === 'Combos' }">Combos</div>
      <div @click="setActiveDetail('Edges')" class="switches__btn" :class="{ active: isActiveDetail === 'Edges' }">Edges</div>
    </div> -->
    <div v-if="isActiveDetail === 'Nodes'" class="item_display">
      <div class="item_display__heading">
        <i title="Download Nodes as CSV" @click="downloadAsCSV" class='bx download-icon bx-download' ></i>
        <span class="ed-badge ed-badge-3">Counts : {{ graphData.nodes.length }}</span>
      </div>
      <div class="item_display__list">
        <ag-grid-vue
          style="width: 100%; height: 500px"
          class="ag-theme-alpine"
          :columnDefs="nodeDefs"
          suppressCsvExport=true
          @cellValueChanged="editItemFromGridNode"
          :rowData="nodeData"
          rowSelection="single"
          @grid-ready="agNodesGridReady"
          domLayout="autoHeight"
          @filter-changed="nodeFilterEvent"
          @selection-changed="agNodeFocus"
        >
        </ag-grid-vue>
        <!-- <li
        class="item_display__list--item"
        v-for="node in displayNodes"
        @click="focusCurrentItem(node.nodeId, 'node')"
        :key="node.nodeId">
        {{node.nodeName}} ({{node.nodeType}})
        </li> -->
      </div>
    </div>
    <div v-if="isActiveDetail === 'Combos'" class="item_display">
      <div class="item_display__heading">
        <i title="Download Combos as CSV" @click="downloadAsCSV" class='bx download-icon bx-download' ></i>
        <span class="badge bg-secondary"
          >Count: {{ graphData.combos.length }}</span
        >
      </div>
      <div class="item_display__list">
        <div v-if="!comboShow" class="combo_div">
          <div
            @click="combosMap(combo)"
            v-for="combo in graphData.combos"
            :key="combo.id"
            class="combo_list"
          >
            <i
              class="bx bx-logo combo_icon"
              style="color:ffd131"
              name="codepen"
            ></i>
            {{ capitalizeWord(combo.label) }}
          </div>
        </div>

        <div v-if="comboShow" class="combo_detail">
          <div class="d-flex justify-content-between">
            <span>{{ activeComboName }}</span>
            <div class="back-btn">
              <i
                name="bx bx-chevron-left"
                @click="comboShow = false"
              ></i>
            </div>
          </div>
          <ag-grid-vue
            style="width: 100%; height: 500px"
            class="ag-theme-alpine"
            :columnDefs="comboDefs"
            suppressCsvExport = true
            :rowData="comboData"
            rowSelection="single"
            @grid-ready="agCombosGridReady"
            domLayout="autoHeight"
            @selection-changed="agComboFocus"
          >
          </ag-grid-vue>
        </div>
        <!-- <li
        class="item_display__list--item"
        v-for="combo in displayCombos"
        @click="focusCurrentItem(combo.comboId, 'combo')"
        :key="combo.comboId">
        {{combo.comboName}} ({{combo.comboType}})
        </li> -->
      </div>
    </div>

    <div v-if="isActiveDetail === 'Edges'" class="item_display">
      <div class="item_display__heading">
        <i title="Download Edges as CSV" @click="downloadAsCSV" class='bx download-icon bx-download' ></i>
        <span class="badge bg-secondary"
          >Count: {{ graphData.edges.length }}</span
        >
      </div>
      <div class="item_display__list">
        <div class="item_display__list">
          <ag-grid-vue
            style="width: 100%; height: 500px"
            class="ag-theme-alpine"
            :columnDefs="edgeDefs"
            :rowData="edgeData"
            suppressCsvExport = true
            rowSelection="single"
            @cellValueChanged="editItemFromGridEdge"
            @grid-ready="agEdgesGridReady"
            domLayout="autoHeight"
            @selection-changed="agEdgeFocus"
            @filter-changed="edgeFilterEvent"
          >
          </ag-grid-vue>
        </div>
        <!-- <li class="item_display__list--item"
          v-for="edge in displayEdges"
          @click="focusCurrentItem(edge.edgeId, 'edge')"
          :key="edge.edgeId">
          {{edge.edgeSource}} - {{edge.edgeName}} - {{edge.edgeTarget}}
        </li> -->
      </div>
    </div>
    <div v-if="isActiveDetail === 'Props'" class="item_display">
      <div class="item_display__heading">
        <i title="Download Properties as CSV" @click="downloadAsCSV" class='bx download-icon bx-download' ></i>
        <span class="ed-badge ed-badge-3">Counts : {{ graphData.nodes.length }}</span>
      </div>
      <div class="item_display__list">
        <ag-grid-vue
          style="width: 100%; height: 500px"
          class="ag-theme-alpine"
          :columnDefs="propDefs"
          :rowData="propData"
          suppressCsvExport = true
          rowSelection="single"
          @grid-ready="agPropsReady"
          @filter-changed="propertiesFilter"
          domLayout="autoHeight"
        >
        </ag-grid-vue>
        <!-- <li
        class="item_display__list--item"
        v-for="node in displayNodes"
        @click="focusCurrentItem(node.nodeId, 'node')"
        :key="node.nodeId">
        {{node.nodeName}} ({{node.nodeType}})
        </li> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  watch,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import { AgGridVue } from 'ag-grid-vue';
import store from '@/store';
import graphReactor from '@/common/core/Graph/graphReactor';
import nodeHsl from '@/common/core/Graph/nodeHsl';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export default {
  components: {
    AgGridVue,
  },
  props: {
    currentSelectedEntity: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    //  ? To make all data inside this reactive
    const data = reactive({
      count: 0,
      graphData: {},
      activeComboName: '',
      comboShow: false,
      isActiveDetail: 'Nodes',
      nodeDefs: [],
      nodeData: [],
      comboDefs: [],
      comboData: [],
      propDefs: [],
      nodeGridApi: null,
      comboGridApi: null,
      propGridApi: null,
      edgeDefs: [],
      edgeData: [],
      edgeGridApi: null,
      propData: [],
      quickSearchParam: '',
    });

    // ? Just to watch properties
    const dataProp = toRefs(data);

    const graphIndexRef = ref(null);

    // ? Data grid definition
    data.nodeDefs = [
      {
        field: 'Id',
        hide: true,
      },
      {
        field: 'Node',
        sortable: true,
        filter: true,
        cellEditorSelector: true,
        editable: true,
      },
      {
        field: 'Type',
        filter: true,
        sortable: true,
        cellEditorSelector: true,
      },
      {
        field: 'Combo',
        filter: true,
        sortable: true,
        cellEditorSelector: true,
      },
    ];

    data.comboDefs = [
      {
        field: 'Id',
        hide: true,
      },
      {
        field: 'Node',
        sortable: true,
        filter: true,
        cellEditorSelector: true,
      },
      {
        field: 'Type',
        filter: true,
        sortable: true,
        cellEditorSelector: true,
      },
    ];
    const propStateData = computed(() => store.getters['gmodule/getProps'].map((prop) => ({ field: prop })));

    data.propDefs = [
      {
        field: 'Id',
        hide: true,
      },
      {
        field: 'Node',
        sortable: true,
        filter: true,
        cellEditorSelector: true,
        editable: true,
      },
      ...propStateData.value,
    ];

    data.edgeDefs = [
      { field: 'Id', hide: true },
      {
        field: 'Source',
        sortable: true,
        filter: true,
        cellEditorSelector: true,
      },
      {
        field: 'Is Linked With',
        sortable: true,
        filter: true,
        cellEditorSelector: true,
        editable: true,
      },
      {
        field: 'Target',
        filter: true,
        sortable: true,
        cellEditorSelector: true,
      },
    ];

    // * Computed Section

    // ? to store g6_explore_data direct from state

    const graphStateData = computed(
      () => store.getters['gmodule/getExploreData'],
    );

    // ? display combops section from graph state
    const displayCombos = computed(() => {
      const filterItem = data.graphData.combos.map((item) => {
        const comboData = {
          comboName: item.label,
          comboId: item.id,
          comboType: item.tjType || 'No Type',
          children: item.children.map((children) => children),
        };
        return comboData;
      });

      return filterItem;
    });

    // * Methods

    function downloadContent(contentData, type, filename) {
      const blob = new Blob([contentData], { type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      document.body.removeChild(a);
    }

    function downloadAsCSV() {
      if (data.isActiveDetail === 'Nodes') {
        console.log('GridCheck', data.nodeGridApi);
        downloadContent(data.nodeGridApi.getDataAsCsv(), 'text/csv', 'nodes.csv');
      }
      if (data.isActiveDetail === 'Combos') {
        downloadContent(data.comboGridApi.getDataAsCsv(), 'text/csv', 'combo.csv');
      }
      if (data.isActiveDetail === 'Props') {
        downloadContent(data.propGridApi.getDataAsCsv(), 'text/csv', 'prop.csv');
      }
      if (data.isActiveDetail === 'Edges') {
        downloadContent(data.edgeGridApi.getDataAsCsv(), 'text/csv', 'edge.csv');
      }
    }

    function editItemFromGridNode(e) {
      const { Id, Node } = e.data;
      if (graphReactor.exploreInstance) {
        const node = graphReactor.exploreInstance.findById(Id);
        node.update({
          id: Node,
          label: Node,
        });
        // store.dispatch('gmodule/saveG6ExploreData');
        graphReactor.exploreInstance.updateLayout();
      }
    }

    function editItemFromGridEdge(e) {
      const { Id } = e.data;
      if (graphReactor.exploreInstance) {
        const edge = graphReactor.exploreInstance.findById(Id);
        edge.update({
          label: e.data['Is Linked With'],
        });
      }
      // store.dispatch('gmodule/saveG6ExploreData');
    }

    function onFilterTextBoxChanged() {
      if (data.isActiveDetail === 'Nodes') {
        data.nodeGridApi.setQuickFilter(
          document.getElementById('filter-text-box').value,
        );
      } else if (data.isActiveDetail === 'Edges') {
        data.edgeGridApi.setQuickFilter(
          document.getElementById('filter-text-box').value,
        );
      } else if (data.isActiveDetail === 'Props') {
        data.propGridApi.setQuickFilter(
          document.getElementById('filter-text-box').value,
        );
      }
    }

    // ? To capitlize word
    function capitalizeWord(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
    // ? focus the selected Item based on the type
    async function focusCurrentItem(id, type) {
      console.log('xvf', props, id, type);
      if (graphReactor.exploreInstance) {
        graphReactor.exploreInstance
          .findAllByState(`${type}`, 'focusEd')
          .forEach((item) => {
            graphReactor.exploreInstance.clearItemStates(item, 'focusEd');
          });
        graphReactor.exploreInstance.focusItem(id);
        graphReactor.exploreInstance.setItemState(id, 'focusEd', true);
      }
      if (data.isActiveDetail === 'Nodes') {
        const wordObj = {
          word: id,
          label: id,
          type: 'string',
          lang: store.state.locale.currentLocale,
        };

        if (store.state.gmodule.edit_mode === 'looked_at') {
          store.dispatch('setCurrentThought', {
            thoughtObject: wordObj,
            excludeCallList: ['gmodule/exploreGraphFetch'],
          });
        }
        if (store.state.gmodule.edit_modedit_mode === 'explore') {
          // store.commit('SET_CURRENT_ENTITY', wordObj);
          await store.dispatch('setCurrentThought', { thoughtObject: wordObj });
        }
      }
    }

    // ? set the active detail section
    function setActiveDetail(mode) {
      data.isActiveDetail = mode;
    }

    // ? assigning Grid ApI to access ag-grid dataProp
    function agNodesGridReady(params) {
      data.nodeGridApi = params.api;
      // data.columnApi = params.columnApi;/
    }

    // ? assigning Grid ApI to access ag-grid dataProp
    function agPropsReady(params) {
      data.propGridApi = params.api;
      // data.columnApi = params.columnApi;/
      // console.log('Assigned allValue');
    }
    function agCombosGridReady(params) {
      data.comboGridApi = params.api;
      // data.columnApi = params.columnApi;/
    }

    // ? To Focus node on Selection of row
    function agNodeFocus() {
      const selectedNodeAg = data.nodeGridApi.getSelectedRows();
      if (store.state.gmodule.edit_modedit_mode === 'remove') {
        if (graphReactor.exploreInstance) {
          const item = graphReactor.exploreInstance.findById(
            selectedNodeAg[0].Id,
          );
          // get Model
          graphReactor.exploreInstance.removeItem(item);
          // store.dispatch('gmodule/saveG6ExploreData');
          graphReactor.exploreInstance.updateLayout();
        }
      } else if (store.state.gmodule.edit_mode === 'hsl') {
        // focusCurrentItem(selectedNodeAg[0].Id, 'node');
        if (graphReactor.exploreInstance) {
          const item = graphReactor.exploreInstance.findById(
            selectedNodeAg[0].Id,
          );
          const itemModel = item.getModel();
          nodeHsl(itemModel, graphReactor.exploreInstance);
          store.dispatch('setCurrentThought', {
            thoughtObject: {
              word: itemModel.label,
              label: itemModel.label,
              type: 'string',
              lang: 'en',
            },
            excludeCallList: ['gmodule/exploreGraphFetch'],
          });
        }
      } else {
        focusCurrentItem(selectedNodeAg[0].Id, 'node');
      }
    }

    function agComboFocus() {
      const comboSelectedNode = data.comboGridApi.getSelectedRows();

      focusCurrentItem(comboSelectedNode[0].Id, 'node');
    }

    // ? update the gridAPI when data changes
    function agEdgesGridReady(params) {
      data.edgeGridApi = params.api;
      // data.columnApi = params.columnApi;/
    }

    // ? To Focus edge on Selection of row
    function agEdgeFocus() {
      const selectedEdgeAg = data.edgeGridApi.getSelectedRows();

      if (store.state.gmodule.edit_mode === 'remove') {
        const item = graphReactor.exploreInstance.findById(
          selectedEdgeAg[0].Id,
        );
        graphReactor.exploreInstance.removeItem(item);
        // store.dispatch('gmodule/saveG6ExploreData');
        graphReactor.exploreInstance.updateLayout();
      } else {
        focusCurrentItem(selectedEdgeAg[0].Id, 'edge');
      }
    }

    // ? Assign graphData with state data (created hook)
    data.graphData = store.getters['gmodule/getExploreData'];

    // ? map the node Data from AG Grid to ag-grid data
    function nodesMap() {
      const nodesUpdateData = [];
      let nodeData;

      data.graphData.nodes.forEach((item) => {
        if (graphReactor.exploreInstance) {
          const gG = graphReactor.exploreInstance.findById(item.id);
          const gGModel = gG.getModel();
          let comboLabel = null;
          // eslint-disable-next-line
        if (gGModel.hasOwnProperty("comboId")) {
            const getCombo = graphReactor.exploreInstance.findById(
              gGModel.comboId,
            );
            comboLabel = getCombo.getModel().label;
          } else {
            comboLabel = 'Stand Alone';
          }
          nodeData = {
            Node: item.label,
            Type: item.tjType || 'No Type',
            Id: item.id,
            Combo: comboLabel,
          };
        } else {
          nodeData = {
            Node: item.label,
            Type: item.tjType || 'No Type',
            Id: item.id,
            Combo: null,
          };
        }

        nodesUpdateData.push(nodeData);
      });
      data.nodeData = nodesUpdateData; // to remove duplcate data
    }

    function propsMap() {
      data.propDefs = [
        {
          field: 'Id',
          hide: true,
        },
        {
          field: 'Node',
          sortable: true,
          filter: true,
          cellEditorSelector: true,
          editable: true,
        },
        ...propStateData.value,
      ];
      const propsDataArray = [];
      data.graphData.nodes.forEach((item) => {
        const propList = {};
        store.getters['gmodule/getProps'].forEach((prop) => {
          const propValue = item.props;
          if (propValue) {
            // eslint-disable-next-line no-prototype-builtins
            if (propValue.hasOwnProperty(prop)) {
              propList[prop] = propValue[prop].value;
            }
          }
        });
        if (item.props) {
          const propData = {
            Id: item.id,
            Node: item.label,
            ...propList,
          };
          propsDataArray.push(propData);
        }
      });
      data.propData = propsDataArray;
    }

    function combosMap(comboPayload) {
      if (graphReactor.exploreInstance) {
        data.comboShow = true;
        data.activeComboName = comboPayload.label;

        const combosUpdateData = [];
        comboPayload.children.forEach((item) => {
          const gG = graphReactor.exploreInstance.findById(item.id);
          const gGModel = gG.getModel();

          const comboLabel = gGModel.label;
          const nodeData = {
            Node: comboLabel,
            Type: gGModel.tjType || 'No Type',
            Id: item.id,
          };

          combosUpdateData.push(nodeData);
        });
        data.comboData = combosUpdateData; // to remove duplcate data
      }
    }

    // ? map the node Data from AG Grid to ag-grid data
    function edgesMap() {
      const edgesUpdateData = [];
      // ! using .save() as we are not getting edgeId from data.graphData in first round
      if (graphReactor.exploreInstance) {
        graphReactor.exploreInstance.save().edges.forEach((item) => {
          const sourceLabel = graphReactor.exploreInstance
            .findById(item.source)
            .getModel().label;
          const targetLabel = graphReactor.exploreInstance
            .findById(item.target)
            .getModel().label;

          const edgeData = {
            Source: sourceLabel,
            'Is Linked With': item.label || 'is linked with',
            Target: targetLabel,
            Id: item.id,
          };

          edgesUpdateData.push(edgeData);
        });
        data.edgeData = edgesUpdateData; // to remove duplcate data
      }
    }

    function setOpacityLabel(item, val) {
      graphReactor.exploreInstance.updateItem(item, {
        labelCfg: {
          style: {
            opacity: val,
          },
        },
      });
    }

    function clearStates() {
      graphReactor.exploreInstance.getNodes().forEach((node) => {
        graphReactor.exploreInstance.clearItemStates(node);
        setOpacityLabel(node, 1);
      });
      graphReactor.exploreInstance.getEdges().forEach((edge) => {
        graphReactor.exploreInstance.clearItemStates(edge);
        setOpacityLabel(edge, 1);
      });

      graphReactor.exploreInstance.getCombos().forEach((combo) => {
        graphReactor.exploreInstance.clearItemStates(combo);
      });
    }

    function updateOpacity() {
      graphReactor.exploreInstance.getNodes().forEach((node) => {
        graphReactor.exploreInstance.clearItemStates(node);
        node.setState('opacityO', true);
        setOpacityLabel(node, 0.1);
      });
      graphReactor.exploreInstance.getEdges().forEach((edge) => {
        graphReactor.exploreInstance.clearItemStates(edge);
        edge.setState('opacityO', true);
        setOpacityLabel(edge, 0.1);
      });
    }

    function nodeFilterEvent() {
      const filteredNodeData = data.nodeGridApi.rowModel.rowsToDisplay.map((row) => ({
        id: row.data.Id,
        tjType: row.data.Type,
      }));

      clearStates();
      updateOpacity();

      filteredNodeData.forEach((node) => {
        const findNode = graphReactor.exploreInstance.find('node', (n) => {
          const nodeModel = n.getModel();
          return ((nodeModel.id === node.id) && (nodeModel.tjType === node.tjType));
        });

        setOpacityLabel(findNode, 1);
        findNode.setState('searchEd', true);

        findNode.getNeighbors().forEach((relatedNode) => {
          setOpacityLabel(relatedNode, 1);
          relatedNode.setState('relatedEdIndex', true);
        });

        console.log(findNode.getEdges());

        findNode.getEdges().forEach((cEdge) => {
          setOpacityLabel(cEdge, 1);
          cEdge.setState('searchEd', true);
        });
      });
    }

    function propertiesFilter() {
      const filteredNodeData = data.propGridApi.rowModel.rowsToDisplay.map((row) => ({
        id: row.data.Id,
      }));

      clearStates();
      updateOpacity();

      filteredNodeData.forEach((node) => {
        const findNode = graphReactor.exploreInstance.find('node', (n) => {
          const nodeModel = n.getModel();
          return ((nodeModel.id === node.id));
        });

        setOpacityLabel(findNode, 1);
        findNode.setState('searchEd', true);

        findNode.getNeighbors().forEach((relatedNode) => {
          setOpacityLabel(relatedNode, 1);
          relatedNode.setState('relatedEdIndex', true);
        });

        console.log(findNode.getEdges());

        findNode.getEdges().forEach((cEdge) => {
          setOpacityLabel(cEdge, 1);
          cEdge.setState('searchEd', true);
        });
      });
    }

    function edgeFilterEvent() {
      const filteredNodeData = data.edgeGridApi.rowModel.rowsToDisplay.map((row) => ({
        id: row.data['Is Linked With'],
        source: row.data.Source,
        target: row.data.Target,

      }));

      clearStates();
      updateOpacity();

      filteredNodeData.forEach((row) => {
        const { source, target } = row;
        const mainNodes = graphReactor.exploreInstance.findAll('node', (n) => {
          const nodeModel = n.getModel();
          return ((nodeModel.id === source) || (nodeModel.id === target));
        });

        const currentEdge = graphReactor.exploreInstance.find('edge', (e) => {
          const edgeModel = e.getModel();
          return ((edgeModel.source === source) && (edgeModel.target === target));
        });

        mainNodes.forEach((currNode) => {
          setOpacityLabel(currNode, 1);
          currNode.setState('searchEd', true);
        });

        setOpacityLabel(currentEdge, 1);
        currentEdge.setState('searchEd', true);
      });
    }

    // * Watch Section

    // ? watch graph state data and change the local data when it changes
    watch(graphStateData, (val) => {
      data.graphData = val;
      // ! using setTimeOut() as we are not getting edgeId from data.graphData in first round
      // ! because of Observer __ json, Don't know why.
      setTimeout(() => {
        nodesMap();

        edgesMap();
        propsMap();
        data.comboShow = false;
      }, 100);
    });

    watch(dataProp.isActiveDetail, (val) => {
      console.log(val, data.quickSearchParam);

      setTimeout(() => {
        if (val === 'Nodes') {
          data.nodeGridApi.setQuickFilter(
            data.quickSearchParam,
          );
        } else if (val === 'Edges') {
          data.edgeGridApi.setQuickFilter(
            data.quickSearchParam,
          );
        } else if (val === 'Props') {
          data.propGridApi.setQuickFilter(
            data.quickSearchParam,
          );
        }
      }, 100);
    });

    // * Mounted Section

    onMounted(() => {
      // ? To get all the data from the store
      nodesMap();
      edgesMap();
      propsMap();

      if (props.currentSelectedEntity && props.currentSelectedEntity.length > 0) {
        setTimeout(() => {
          focusCurrentItem(props.currentSelectedEntity, 'node');
        }, 1000);
      }
    });

    return {
      ...dataProp,
      // displayNodes,
      displayCombos,
      downloadAsCSV,
      graphIndexRef,
      onFilterTextBoxChanged,
      editItemFromGridNode,
      editItemFromGridEdge,
      capitalizeWord,
      focusCurrentItem,
      setActiveDetail,
      agCombosGridReady,
      // displayEdges,
      agNodesGridReady,
      agNodeFocus,
      agEdgesGridReady,
      agEdgeFocus,
      agComboFocus,
      combosMap,
      agPropsReady,
      nodeFilterEvent,
      propertiesFilter,
      edgeFilterEvent,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";

.download-csv {
  border: 1px solid #eee;
  display: flex;
  align-items: center;
}

.download-icon  {
  font-size: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform:scale(1.1);
  }
}

.combo_div {
  display: flex;
  flex-wrap: wrap;
  .combo_list {
    width: 20%;
    height: auto;
    // border: 1px solid #333;
    max-height: 6rem;
    margin: 0.5rem;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;

    &:hover {
      cursor: pointer;
      // subtle shdown
      box-shadow: 0px 0px 4px 5px rgba(54, 54, 54, 0.178);
    }

    .combo_icon {
      height: 70%;
      width: 100%;
    }
  }
}

.nav-tabs {
  .nav-item {
    .nav-link {
      color: $color-grey-1;
    }
    .nav-link.active {
      color: $edvantaBlue;
    }

    &:focus {
      outline: none;
    }
  }
}

.switches {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__btn {
    padding: 0.5em;
    // border: 1px solid black;
    border-radius: 5px;
    margin: 0.2rem 0;
    cursor: pointer;
    transition: all ease-in-out 200ms;
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    align-items: center;
    &:not(:first-child) {
      margin-left: 1rem;
    }
    &:hover {
      background-color: #fff;
    }
  }
  .active {
    background-color: lighten($accentYellow, 10%);
    color: #fff;
  }
}
.item_display {
  &__heading {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    span {
      margin-left: auto;
      cursor: default;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: lighten($black, 50%);

    &--item {
      transition: all ease-in-out 100ms;
      &:hover {
        cursor: pointer;
        color: $black;
      }
    }
  }

  .rowClass {
    margin: 0;
  }

  .columnClass {
    max-width: 50px;
  }
}
</style>
