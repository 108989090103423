import { render, staticRenderFns } from "./DetailWidget.vue?vue&type=template&id=509ad331&scoped=true"
import script from "./DetailWidget.vue?vue&type=script&lang=js"
export * from "./DetailWidget.vue?vue&type=script&lang=js"
import style0 from "./DetailWidget.vue?vue&type=style&index=0&id=509ad331&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509ad331",
  null
  
)

export default component.exports